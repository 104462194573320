import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.boards
const COLUMN_API_URL = apiEndpoints.boardColumn
const TASK_STATUS_API_URL = apiEndpoints.taskStatus
const TASK_API_URL = apiEndpoints.task

export const BoardsApi = createApi({
	reducerPath: 'Boards',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Boards', 'BoardColumn', 'TaskStatus', 'Task'],
	endpoints: (builder) => ({
		getBoards: builder.query({
			query: (params) => ({
				url: API_URL,
				params,
				method: 'GET',
			}),
			providesTags: ['Boards'],
			transformResponse: (response) => {
				const Boards = Array.isArray(response?.data)
					? response?.data.map((boards) => ({
							...boards,
							id: boards?.id,
						}))
					: []

				return {
					boards: Boards,
					meta: response?.data?.meta,
				}
			},
		}),

		getBoard: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Boards', 'BoardColumn', 'TaskStatus', 'Task'],
		}),

		createboards: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Boards'],
		}),

		updateboards: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Boards'],
		}),

		deleteboards: builder.mutation({
			query: ({ id, payload }) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
				data: payload,
			}),
			invalidatesTags: ['Boards'],
		}),

		getBoardColumnsByCategory: builder.query({
			query: (params) => ({
				url: `${COLUMN_API_URL}/by-board-and-category`,
				method: 'GET',
				params: params,
			}),
		}),

		createBoardColumn: builder.mutation({
			query: (payload) => ({
				url: COLUMN_API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['BoardColumn'],
		}),

		updateBoardColumn: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${COLUMN_API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
		}),

		deleteBoardColumn: builder.mutation({
			query: (id) => ({
				url: `${COLUMN_API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['BoardColumn'],
		}),

		getCategories: builder.query({
			query: () => ({
				url: `/categories`,
				method: 'GET',
			}),
		}),

		createTaskStatus: builder.mutation({
			query: (payload) => ({
				url: TASK_STATUS_API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['TaskStatus'],
		}),

		updateTaskStatus: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${TASK_STATUS_API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['TaskStatus'],
		}),

		deleteTaskStatus: builder.mutation({
			query: (id) => ({
				url: `${TASK_STATUS_API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['TaskStatus'],
		}),

		// task
		createTask: builder.mutation({
			query: (payload) => ({
				url: TASK_API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Task'],
		}),
		updateTask: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${TASK_API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Task'],
		}),

		// for task listing
		getTasksByBoard: builder.query({
			query: ({ boardId, params }) => ({
				url: `${API_URL}/${boardId}/list`,
				method: 'GET',
				params,
			}),
			providesTags: ['Task'],
		}),

		bulkActionTask: builder.mutation({
			query: (payload) => ({
				url: `${TASK_API_URL}/bulk-update`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Task'],
		}),
	}),
})

export const {
	// board
	useLazyGetBoardsQuery,
	useLazyGetBoardQuery,
	useCreateboardsMutation,
	useUpdateboardsMutation,
	useDeleteboardsMutation,

	// board column
	useCreateBoardColumnMutation,
	useUpdateBoardColumnMutation,
	useDeleteBoardColumnMutation,
	useLazyGetCategoriesQuery,
	useLazyGetBoardColumnsByCategoryQuery,

	// task status
	useCreateTaskStatusMutation,
	useUpdateTaskStatusMutation,
	useDeleteTaskStatusMutation,

	// task
	useCreateTaskMutation,
	useUpdateTaskMutation,

	// for task listing
	useLazyGetTasksByBoardQuery,

	useBulkActionTaskMutation,
} = BoardsApi
