import classNames from 'classnames'
import { Columns3 } from 'lucide-react'
import { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { Button } from 'theme/ui/forms'

const ColumnVisibilityContainer = ({ table, columnVisibility }) => {
	const [isTagOpen, setIsTagOpen] = useState(false)
	const toggleTagSection = () => {
		setIsTagOpen(!isTagOpen)
	}

	const { setColumnVisibility } = table
	const columns =
		Object.keys(table).length > 0 && Array.isArray(table?.getAllLeafColumns())
			? table?.getAllLeafColumns()
			: []

	return (
		<Dropdown
			isOpen={isTagOpen}
			className=''
			direction='left'
			toggle={() => toggleTagSection()}>
			<Button
				type='button'
				size='sm'
				variant='white'
				// className={classNames('btn btn-secondary', {
				// 	'!bg-main/20 !text-main !shadow-md': isTagOpen,
				// })}
			>
				<DropdownToggle tag='span' className='m-0 !border !border-none bg-transparent p-0'>
					<div className='flex items-center gap-1'>
						<span>
							<Columns3 size={14} />
						</span>
						<span>Columns</span>
					</div>
				</DropdownToggle>
			</Button>
			<DropdownMenu className='dropdown-menu-md dropdown-menu-start mt-2 p-0 pt-2 shadow-md'>
				<div className='flex flex-col'>
					{columns.map((column) => {
						const isChecked = columnVisibility[column.id]
						return (
							<label
								key={column.id}
								htmlFor={column.id}
								className={classNames(
									'm-0 flex items-center space-x-2',
									isChecked ? 'exp-dropdown-item-active' : 'exp-dropdown-item',
								)}>
								<input
									{...{
										type: 'checkbox',
										id: column.id,
										className: 'form-check-input',
										checked: isChecked,
										onChange: () => {
											setColumnVisibility((prev) => ({
												...prev,
												[column.id]: !prev[column.id], // Toggle visibility for this column
											}))
										},
									}}
								/>
								<span className='capitalize'>
									{column?.id.replaceAll('_', ' ')}
								</span>
							</label>
						)
					})}
				</div>
			</DropdownMenu>
		</Dropdown>
	)
}
export default ColumnVisibilityContainer
