import { useAppRouter } from 'hooks'
import { useLazyGetTasksByBoardQuery } from 'modules/scrum-boards/boards-api'
import { Fragment, useEffect, useState } from 'react'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import { useDeleteTaskMutation } from '../task-api'
import Filters from './filters'
import TaskTable from './task-table'

const TaskListingContainer = ({ boardId, board }) => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const [rowSelection, setRowSelection] = useState({})
	const [columnVisibility, setColumnVisibility] = useState({
		'#': true,
		summary: true,
		workflow: true,
		progress_status: true,
		assignee: true,
		actions: true,
	})
	const [selectedTaskId, setSelectedTaskId] = useState(null) // Track selected task
	const [isCanvasOpen, setIsCanvasOpen] = useState(false) // Control canvas visibility

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	// const [fetchTasks, { data, isLoading }] = useLazyGetTasksQuery()
	const [fetchTasksByBoard, { data, isUninitialized, isFetching }] = useLazyGetTasksByBoardQuery()

	useEffect(() => {
		if (boardId && location?.search) {
			const query = getParseQueryString(location?.search)
			// const params = { sort: 'due_date', order: 'ASC', page: pageIndex + 1, limit: pageSize }

			fetchTasksByBoard({
				boardId,
				params: query,
			})
		}
	}, [boardId, location?.search])

	const handleOpenCanvas = (taskId) => {
		setSelectedTaskId(taskId)
		setIsCanvasOpen(true)
	}

	const handleCloseCanvas = () => {
		setSelectedTaskId(null)
		setIsCanvasOpen(false)
	}

	useEffect(() => {
		if (isCanvasOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}
		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [isCanvasOpen])

	const [deleteTask] = useDeleteTaskMutation()

	const onDeleteTask = async (values, id) => {
		try {
			const response = await deleteTask({ id, payload: values })
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			if (boardId) {
				fetchTasksByBoard({ boardId, params: { sort: 'due_date', order: 'ASC' } })
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<Fragment>
			<Filters
				setPagination={setPagination}
				type='list'
				board={board}
				columnVisibility={columnVisibility}
				setColumnVisibility={setColumnVisibility}
				setRowSelection={setRowSelection}
				rowSelection={rowSelection}
			/>
			<TaskTable
				data={data?.data?.tasks || []}
				meta={data?.data?.meta}
				onDeleteTask={onDeleteTask}
				isLoading={isUninitialized || isFetching}
				pagination={pagination}
				setPagination={setPagination}
				columnVisibility={columnVisibility}
				setColumnVisibility={setColumnVisibility}
				onOpenCanvas={handleOpenCanvas} // Pass callback to open canvas
				selectedTaskId={selectedTaskId}
				isCanvasOpen={isCanvasOpen}
				onCloseCanvas={handleCloseCanvas}
				boardId={boardId}
				setRowSelection={setRowSelection}
				rowSelection={rowSelection}
			/>
		</Fragment>
	)
}

export default TaskListingContainer
