import classNames from 'classnames'
import { ChevronDown } from 'lucide-react'
import { useUpdateTaskMutation } from 'modules/scrum-boards/boards-api'
import { useLazyGetTaskStatuseByBoardQuery } from 'modules/scrum-boards/task-status-api'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Button } from 'reactstrap'
import notification from 'utilities/notification'
import { createStatusOption, TaskStatusLabel } from '../task-form/status-dropdown'

const StatusColumn = ({ task, boardId, isDropdown = false }) => {
	const [updateTask] = useUpdateTaskMutation()
	const [fetchStatus, { data }] = useLazyGetTaskStatuseByBoardQuery()
	const [isOpen, setIsOpen] = useState(false)
	useEffect(() => {
		if (boardId && isDropdown) {
			fetchStatus({ boardId })
		}
	}, [boardId, isDropdown])

	const statusOptions = useMemo(
		() => (Array.isArray(data?.data) ? data?.data.map((s) => createStatusOption(s)) : []),
		[data?.data],
	)

	const selectedOption = useMemo(
		() => statusOptions.find((options) => options?.value === task?.task_status_id),
		[statusOptions, task?.task_status_id],
	)

	const onStatusChange = async (selectedStatus) => {
		const response = await updateTask({
			payload: { task_status_id: selectedStatus, task_title: task?.title },
			id: task?.id,
		}).unwrap()
		if (response?.status === 200) {
			notification('success', response?.message)
		}
		setIsOpen(false)
	}

	if (!isDropdown) {
		return <TaskStatusLabel status={task?.task_status} />
	}
	return (
		<Fragment>
			<Dropdown
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				target={
					<Button
						onClick={() => setIsOpen((prev) => !prev)}
						type='button'
						size='xs'
						color='secondary'
						className={classNames(
							'!rounded-md px-2 py-1 text-xs font-semibold text-white flex items-center space-x-1',
						)}
						style={{
							backgroundColor: selectedOption?.color,
							position: 'relative',
							zIndex: 10000, // Ensure button is above other content
						}}>
						<span>{selectedOption?.label || 'Select Status'}</span>
						<ChevronDown size={16} />
					</Button>
				}>
				<div className='py-1'>
					{statusOptions.map((status, i) => (
						<div
							key={i}
							className='px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm'
							style={{
								backgroundColor:
									status.value === selectedOption?.value
										? '#f5f5f5'
										: 'transparent',
							}}
							onClick={() => onStatusChange(status.value)}>
							<span
								className='inline-block w-3 h-3 rounded-full mr-2'
								style={{ backgroundColor: status.color }}
							/>
							{status.label}
						</div>
					))}
				</div>
			</Dropdown>
		</Fragment>
	)
}

// Dropdown Components
const Menu = (props) => {
	const shadow = 'hsla(218, 50%, 10%, 0.1)'
	return (
		<div
			style={{
				backgroundColor: 'white',
				borderRadius: 4,
				boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
				marginBottom: 8, // Changed from marginTop to marginBottom
				position: 'absolute',
				bottom: '100%', // Position above the trigger
				zIndex: 9999, // High z-index to appear above everything
			}}
			{...props}
		/>
	)
}

const Blanket = (props) => (
	<div
		style={{
			bottom: 0,
			left: 0,
			top: 0, // Changed from top: 10
			right: 0,
			position: 'fixed',
			zIndex: 9998, // Just below the menu but above other content
		}}
		{...props}
	/>
)

const Dropdown = ({ children, isOpen, target, onClose }) => (
	<div style={{ position: 'relative' }}>
		{target}
		{isOpen ? <Menu>{children}</Menu> : null}
		{isOpen ? <Blanket onClick={onClose} /> : null}
	</div>
)

export default StatusColumn
