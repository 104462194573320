import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'

const API_URL = apiEndpoints.task

export const taskApi = createApi({
	reducerPath: 'Tasks',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Tasks', 'Kanban', 'TaskUpdate'],
	endpoints: (builder) => ({
		getTasks: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				params: params,
				method: 'GET',
			}),
			providesTags: ['TaskUpdate'],
			transformResponse: (response, _, arg) => {
				const tasks = Array.isArray(response?.data?.task)
					? response?.data?.task.map((task, idx) => ({
							...task,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: task?.id,
						}))
					: []

				return {
					tasks: tasks,
					meta: response?.data?.meta,
				}
			},
		}),

		getTask: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Tasks'],
		}),

		createTask: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Tasks', 'TaskUpdate'],
		}),

		updateTask: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['TaskUpdate'],
		}),

		updateTaskStatus: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}/update-status`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Tasks', 'Kanban'],
		}),

		deleteTask: builder.mutation({
			query: ({ id, payload }) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
				data: payload,
			}),
			invalidatesTags: ['TaskUpdate'],
		}),

		getTaskTags: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: `${API_URL}/tags`,
				params: params,
				method: 'GET',
			}),
			transformResponse: (response, _, arg) => {
				const tags = Array.isArray(response?.data?.tags)
					? response?.data?.tags.map((tag, idx) => ({
							...tag,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: tag?.id,
						}))
					: []

				return {
					tags: tags,
					meta: response?.data?.meta,
				}
			},
		}),

		getTaskStatuses: builder.query({
			query: (params) => ({
				url: `${API_URL}/status-list`,
				params: params,
				method: 'GET',
			}),
		}),

		getCardView: builder.query({
			query: (params) => ({
				url: `${API_URL}/card-view`,
				params: params,
				method: 'GET',
			}),
			providesTags: ['Kanban'],
			transformResponse: (response) => {
				const columns = Array.isArray(response?.data) ? response?.data : []
				return {
					columns: columns,
				}
			},
		}),

		// bulkActionTask: builder.mutation({
		// 	query: (payload) => ({
		// 		url: `${API_URL}/bulk-update`,
		// 		method: 'POST',
		// 		data: payload,
		// 	}),
		// 	invalidatesTags: ['TaskUpdate'],
		// }),

		getTaskHistory: builder.query({
			query: (taskId) => ({
				url: `${API_URL}/${taskId}/history`,
				method: 'GET',
			}),
			transformResponse: (response) => {
				return Array.isArray(response?.data) ? response.data : []
			},
			providesTags: ['TaskUpdate'],
		}),
	}),
})

export const {
	useLazyGetTasksQuery,
	useLazyGetTaskQuery,
	// useCreateTaskMutation,
	// useUpdateTaskMutation,
	useUpdateTaskStatusMutation,
	useDeleteTaskMutation,
	useLazyGetTaskTagsQuery,
	useLazyGetTaskStatusesQuery,
	useLazyGetCardViewQuery,

	// useBulkActionTaskMutation,

	useLazyGetTaskHistoryQuery,
} = taskApi
