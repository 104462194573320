import { useDraggable } from '@dnd-kit/core'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Tooltip } from 'reactstrap'

import userPlaceholder from 'assets/images/users/avatar1.jpg'
import moment from 'moment'
import { convertToTranslate } from '.'
import Clients from './clients'
import Tags from './tags'

const TaskCard = ({ task, index, onOpenCanvas }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const [assigneeTooltipOpen, setAssigneeTooltipOpen] = useState(false)

	const toggle = () => setTooltipOpen(!tooltipOpen)
	const assigneeToggle = () => setAssigneeTooltipOpen(!assigneeTooltipOpen)

	const { active, attributes, listeners, setNodeRef, transform } = useDraggable({
		id: task?.id,
		data: {
			index,
			task,
			parent: task?.parent,
			type: snakeCase(task?.parent),
		},
	})

	const handleTitleClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (onOpenCanvas) {
			onOpenCanvas(task?.id, task?.parent) // Open TaskCanvas
		} else {
			console.error('onOpenCanvas is not defined')
		}
	}

	const transForm = useMemo(() => convertToTranslate(transform), [transform])
	const assignee = task?.assignee || null

	const attachments = Array.isArray(task?.attachments) ? task?.attachments : []

	return (
		<React.Fragment>
			<div
				className={classNames(
					'space-y-2 rounded !border bg-white p-[10px]',
					active?.id === task?.id ? '!z-[1000] border-main shadow-md' : 'drop-shadow-sm',
				)}
				style={{ transform: transForm }}
				ref={setNodeRef}
				{...attributes} // Drag attributes
				role='button'>
				{/* Drag Handle */}

				<Tooltip
					isOpen={tooltipOpen}
					target={`task-${task?.id}`}
					toggle={toggle}
					placement='auto'>
					<p className='m-0 text-xs'>{task?.task_title}</p>
				</Tooltip>

				<div className='flex justify-between'>
					<span
						id={`task-${task?.id}`}
						onClick={handleTitleClick}
						className='line-clamp-3 flex-1 cursor-pointer break-words text-sm !text-gray-700 hover:!underline'>
						{task?.task_title}
					</span>

					<span
						className='cursor-move text-gray-500 hover:text-gray-700 mr-2'
						{...listeners}>
						⋮⋮
					</span>
				</div>

				<Tags tags={task?.tags} />

				{/* <div className='flex items-center space-x-2 rounded py-2 text-xs'>
					<span>{task?.team?.team_name}</span>
				</div> */}

				{task?.workflow?.title ? (
					<span className='tag tag-info line-clamp-1 max-w-fit'>
						<i className='uil-list-ul mr-1' />
						{task?.workflow?.title}
					</span>
				) : undefined}

				<Clients clients={task?.clients} />

				<div className='flex justify-between border-t pt-2'>
					{task?.due_date || (Array.isArray(attachments) && attachments?.length > 0) ? (
						<div>
							{task?.due_date ? (
								<span className='text-success rounded bg-green-100 p-1 text-xs'>
									{moment(task?.due_date).format('D, MMM YYYY')}
								</span>
							) : undefined}

							{attachments.length > 0 ? (
								<span className='rounded bg-gray-100 px-1'>
									<i className='uil-paperclip text-base' />
									&nbsp;{attachments?.length}
								</span>
							) : undefined}
						</div>
					) : (
						<div></div>
					)}

					<div className=''>
						<Tooltip
							isOpen={assigneeTooltipOpen}
							target={`assignee-${task?.id}`}
							toggle={assigneeToggle}
							placement='auto'>
							{assignee ? (
								<p className='m-0 text-xs'>
									{assignee?.first_name + ' ' + assignee?.last_name}
								</p>
							) : (
								<p className='m-0 text-xs'>Unassign</p>
							)}
						</Tooltip>
						<img
							id={`assignee-${task?.id}`}
							src={assignee?.avatar?.path || userPlaceholder}
							alt=''
							className='aspect-square h-[28px] rounded-full'
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default TaskCard
