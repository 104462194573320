import KanbanViewContainer from './kanban-view-container'

export const columns1 = [
	{
		id: 1,
		title: 'TO DO LIST',
		cards: [
			{
				id: 1,
				title: 'Add card',
				description: 'Add capability to add a card in a column',
			},
		],
	},
	{
		id: 2,
		title: 'IN PROCESS',
		cards: [
			{
				id: 2,
				title: 'Drag-n-drop support',
				description: 'Move a card between the columns',
			},
		],
	},
	{
		id: 3,
		title: 'IN REVIEW',
		cards: [
			{
				id: 2,
				title: 'Drag-n-drop support',
				description: 'Move a card between the columns',
			},
		],
	},
	{
		id: 4,
		title: 'COMPLETED',
		cards: [
			{
				id: 2,
				title: 'Drag-n-drop support',
				description: 'Move a card between the columns',
			},
		],
	},
]

export const COLUMNS = {
	TO_DO_LIST: 'To Do',
	IN_PROCESS: 'In Process',
	IN_REVIEW: 'In Review',
	COMPLETED: 'Completed',
}

export const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_TASKS':
			return { ...state, [action.column]: action.tasks }
		case 'MOVE_TASK': {
			const { from, to, task, index } = action
			const updatedFrom = state[from].filter((_, i) => i !== index)
			const updatedTo = [...state[to], task]
			return { ...state, [from]: updatedFrom, [to]: updatedTo }
		}
		case 'ADD_COLUMN':
			return { ...state, [action.title]: [] }
		default:
			return state
	}
}

export const MAX_COLUMNS = 8

export const getMappedColumns = (columns) => {
	return Array.isArray(columns)
		? columns
				.filter((c) => c?.task_statuses?.length > 0)
				.map((c) => ({
					id: c?.id,
					name: c?.name,
					category_id: c?.category_id,
					category: c?.category,
					statuses: Array.isArray(c?.task_statuses)
						? c?.task_statuses.map((s) => ({
								id: s?.id,
								name: s?.name,
								parent: c?.name,
							}))
						: [],
					cards: Array.isArray(c?.tasks)
						? c?.tasks.map((t) => ({
								...t,
								parent: c?.name,
							}))
						: [],
				}))
		: []
}

export default KanbanViewContainer
