import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useMemo } from 'react'

import userIcon from 'assets/svg/user-plus-icon.svg'
import { Form, FormikProvider, useFormik } from 'formik'
import { useBulkActionTaskMutation } from 'modules/scrum-boards/boards-api'
import { useLazyGetTaskStatuseByBoardQuery } from 'modules/scrum-boards/task-status-api'
import AssigneeDropdown from 'modules/tasks/task-form/assignee-dropdown'
import StatusDropdown from 'modules/tasks/task-form/status-dropdown'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, getValidationSchema } from '.'
import { POPUP_STATES } from '..'

const ChangeAssigneePopup = ({
	isOpen,
	onClose,
	selectedRows,
	key,
	title,
	buttonLabel,
	actionType,
	boardId,
}) => {
	const [bulkAction] = useBulkActionTaskMutation()
	const validationSchema = useMemo(() => getValidationSchema(actionType), [actionType])

	const formik = useFormik({
		initialValues: {
			[key]: null,
			task_ids: selectedRows,
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values, actionType)
				const response = await bulkAction(payload).unwrap()

				if (response?.status === 200) {
					onClose()
					notification('success', response?.message)
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				notification('error', error?.message)
			}
		},
	})

	console.log('formik', formik?.errors)

	const [fetchStatus, { data, isLoading }] = useLazyGetTaskStatuseByBoardQuery()
	useEffect(() => {
		if (boardId) {
			fetchStatus({ boardId })
		}
	}, [boardId])

	const statusOptions = useMemo(() => (Array.isArray(data?.data) ? data?.data : []), [data?.data])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel
								className={`w-full max-w-xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute  left-6 top-6 rounded-full !border !border-gray-300  bg-white p-3 '>
										<img src={userIcon} alt='' className='' />
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<FormikProvider value={formik}>
									<Form>
										<div className='mt-7 flex flex-col p-3'>
											<div className='flex flex-col justify-start'>
												<Dialog.Title
													as='h2'
													className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
													{title}
												</Dialog.Title>
												<div>
													{actionType === POPUP_STATES.ASSIGNEE ? (
														<AssigneeDropdown />
													) : undefined}
													{actionType === POPUP_STATES.STATUS ? (
														<StatusDropdown
															name='task_status_id'
															isLoading={isLoading}
															options={statusOptions}
														/>
													) : undefined}
												</div>
											</div>
											<hr className='mt-3 bg-gray-300' />

											<div className='flex w-full justify-center px-6  gap-2'>
												<Button
													type='submit'
													isLoading={formik.isSubmitting}>
													{buttonLabel}
												</Button>
												<Button
													variant='ghost'
													type='button'
													onClick={() => {
														onClose()
														formik.resetForm()
													}}>
													Cancel
												</Button>
											</div>
										</div>
									</Form>
								</FormikProvider>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default ChangeAssigneePopup
